import { List, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocales } from '../../../locales';
import { StyledSubheader } from './styles';
import NavList from './NavList';
import NavListOnClick from './NavListOnClick';

interface NavSectionVerticalProps {
  sx?: any;
  data?: any;
}

const StyledList = styled(List)(({ theme }) => ({
  px: 0.5,
  '&::after': {
    content: '""',
    display: 'block',
    height: 1,
    boxShadow: `inset 0 -0.2px 0 0 ${theme.palette.common.white}`,
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
}));

import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import SvgColor from '@/components/svg-color';
import { useAtom, useAtomValue } from 'jotai';
// import { navConfigAtom } from '@/hooks/useInitData';
import { useStateDataRequest } from '@/src/communication/socket/dataRequest';
import { SocketEmits } from '@api-types';
import navConfig from '@/layouts/dashboard/nav/config-navigation';
import { PATH_DASHBOARD } from '@/routes/paths';
import { atomWithStorage } from 'jotai/utils';
import AppConfig from '@/config/config';
import ChoosePortraitSetDropDown from '@/sections/dashboard/app/ChoosePortraitSetDropDown';

type NavigationData = { category: string; mainCategory: string; icon?: string }[];
const initData = atomWithStorage<NavigationData | null>('navigationData', null);
export const portraitSetsMainCategoryAtom = atomWithStorage<string>('mainCategory', 'photoshooting');

export default function NavSectionVertical({ sx, ...other }: NavSectionVerticalProps) {
  const { translate } = useLocales();
  const [showMore, setShowMore] = useState(true);
  const [globalData, setGlobalData] = useAtom(initData);
  const [refreshGlobalData, setRefreshGlobalData] = useState(false);
  const mainCategory = useAtomValue(portraitSetsMainCategoryAtom);
  const navigationConfigRef = useRef(navConfig);
  const [isDataReady, setIsDataReady] = useState(false);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);

  const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
  useStateDataRequest(SocketEmits.GetCategories, {}, setGlobalData);

  useEffect(() => {
    if (globalData && globalData.length > 0 && mainCategory) {
      const newFilteredItems = globalData
        .filter((category) => category.mainCategory === mainCategory)
        .map((category) => ({
          title: category.category,
          mainCategory: category.mainCategory,
          path: `${PATH_DASHBOARD.general.app}?discover=${encodeURIComponent(category.category)}`,
          icon: category.icon ? icon(category.icon) : icon('ic_blank'),
          img: category.category.startsWith('categories.')
            ? `${AppConfig.publicCategoryImages}/${category.category.replace('categories.', '')}.jpg`
            : undefined,
        }))
        .sort((a, b) => {
          const translatedTitleA = translate(a.title);
          const translatedTitleB = translate(b.title);
          return translatedTitleA.localeCompare(translatedTitleB);
        });

      setFilteredItems(newFilteredItems);
      setIsDataReady(true);
    }
  }, [globalData, mainCategory]);

  const handleClick = () => {
    setShowMore(!showMore);
  };

  // Nav auto expand
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    // If the ref is not attached to an element, do nothing.
    if (!containerRef.current) {
      return;
    }

    // Get the height of the container.
    const containerHeight = containerRef.current.offsetHeight;
    if (containerHeight === 0) {
      return;
    }

    if (containerHeight < window.innerHeight - 300) {
      // If the height of the container is less than the height of the viewport, show all items.
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }, []);

  return (
    <>
      {isDataReady && ( // Render only when data is ready
        <Stack sx={sx} {...other} ref={containerRef}>
          <ChoosePortraitSetDropDown />
          {navigationConfigRef.current.map((group: { subheader?: string; subHeader?: string; items: any[] }, index: number) => {
            const showMaxEntries = 5;
            const key = group.subheader || group.items[0].title;
            const items = index === 1 ? (showMore ? filteredItems : filteredItems.slice(0, showMaxEntries)) : group.items;

            return (
              <StyledList key={key} disablePadding sx={{ mt: 2 }}>
                {group.subheader && <StyledSubheader disableSticky index={index}>{`${translate(group.subheader)}`}</StyledSubheader>}

                {items.map((list) => (
                  <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
                ))}

                {index === 1 && filteredItems.length > showMaxEntries && !showMore && (
                  <NavListOnClick
                    key="showMore"
                    data={{
                      title: translate('menu.showMore'),
                      icon: icon('arrow-down-s-line'),
                      path: '###',
                    }}
                    depth={1}
                    hasChild={false}
                    onClicking={handleClick}
                  />
                )}
                {index === 1 && filteredItems.length > showMaxEntries && showMore && (
                  <NavListOnClick
                    key="showLess"
                    data={{
                      title: translate('menu.showLess'),
                      icon: icon('arrow-up-s-line'),
                      path: '###',
                    }}
                    depth={1}
                    hasChild={false}
                    onClicking={handleClick}
                  />
                )}
              </StyledList>
            );
          })}
        </Stack>
      )}
    </>
  );
}
