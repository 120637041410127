import { useState, MouseEvent, useEffect } from 'react';
import { Box, MenuItem, Stack, Typography } from '@mui/material';
import { useLocales } from '../../../locales';
import Image from '../../../components/image';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { usePostDataRequest } from '@/src/communication/socket/dataRequest';
import { SocketEmits } from '@api-types';

export default function LanguagePopover() {
  const { allLangs, currentLang, onChangeLang } = useLocales();
  const [openPopover, setOpenPopover] = useState<null | HTMLElement>(null);
  const postDataRequest = usePostDataRequest();

  useEffect(() => {
    if (currentLang?.value) {
      postDataRequest(SocketEmits.UpdateLanguageSetting, { language: currentLang?.value });
    }
  }, [currentLang?.value]);

  const handleOpenPopover = (event: MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLanguage: string) => {
    onChangeLang(newLanguage);
    postDataRequest(SocketEmits.UpdateLanguageSetting, { language: newLanguage });
    handleClosePopover();
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          ...(openPopover && { bgcolor: 'action.selected' }),
          opacity: 0.5,
        }}
      >
        <Image disabledEffect src={currentLang?.icon} alt={currentLang?.label} sx={{ width: 28, height: 20 }} />
      </IconButtonAnimate>

      <MenuPopover open={Boolean(openPopover)} anchorEl={openPopover} onClose={handleClosePopover} sx={{ width: 'auto', minWidth: 300 }}>
        <Stack spacing={1} sx={{ p: 2 }}>
          {allLangs.map((option) => (
            <MenuItem key={option.value} selected={option.value === currentLang?.value} onClick={() => handleChangeLang(option.value)}>
              <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, height: 20, mr: 2 }} />
              <Typography variant="body2" noWrap>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
